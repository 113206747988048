<template>
  <default-layout>
    <div class="register-sucess my-5 px-6">
      <div v-if="fromOTP">
        <img
          src="@/assets/images/regissuccess.svg"
          alt="register-sucess"
          class="inline"
        />
        <h2 class="mt-4">Chúc mừng bạn</h2>
        <div class="font-bold mb-4" style="font-size: 20px">
          Đã tạo tài khoản thành công tại
          <span class="text-primary">Interloan</span>
        </div>
      </div>
      <div v-else>
        <h2 class="mt-4">Cho Interloan hỏi bạn đang làm ở công ty nào?</h2>
      </div>
      <p class="my-4">
        Để hoàn thành hồ sơ bạn hãy cho biết hiện tại bạn đang công tác ở công
        ty nào?
      </p>
      <base-combobox
        placeholder="Công ty của bạn"
        name="company"
        v-model:modelValue="company"
        v-model:modelId="companyId"
        :data="arrCompany"
        :textCenter="true"
        :maxItem="1"
      />
      <!-- <base-auto-complete
        placeholder="Công ty của bạn"
        name="company"
        v-model:modelValue="company"
        v-model:modelId="companyId"
        :data="arrCompany"
        :textCenter="true"
        :maxItem="1"
      /> -->
      <!-- <base-input
        v-model:modelValue="company"
        typeInput="text"
        text-center
        placeholder="Công ty bạn đang làm việc"
        @change-data="changeData"
      /> -->
      <base-text-error :errorMessages="errorMess" />
      <base-button
        @click="handleSubmit"
        class="w-full border-16 bg-tertiary text-white mt-7 py-6"
        >Tiếp tục
      </base-button>
    </div>
  </default-layout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import BaseTextError from '@/components/Base/BaseTextError.vue';
import constRouter from '@/constants/constRouter';
import { required } from '@/helpers/validate';
import { mapMutations, mapGetters, mapActions } from 'vuex';
import { updateCompany, updateCustomer } from '@/apis/ApiOnboarding';
import { getCompany } from '@/apis/ApiGeneral';
import { createLoan } from '@/apis/ApiBorrower';
import { setContractId } from '@/helpers/localStorage';
import BaseCombobox from '@/components/Base/BaseCombobox.vue';

export default defineComponent({
  components: {
    DefaultLayout,
    BaseButton,
    BaseTextError,
    BaseCombobox,
  },
  name: 'RegisterCompany',
  data() {
    return {
      fromOTP: true,
      company: '',
      companyId: '',
      errorMess: '',
      arrCompany: [],
    };
  },
  created() {
    getCompany().then(res => {
      this.arrCompany = res.data?.data;
    });
  },
  mounted() {
    updateCustomer('', {
      monthlyIncome: this.getExigency.monthlyIncome,
      salaryDate: this.getExigency.dateSalary,
      email: this.getEmail,
    });
    this.fetchUserInfo();
  },
  computed: {
    ...mapGetters({
      getEmail: 'getEmail',
      getExigency: 'getExigency',
      campaign: 'getCampaign',
    }),
  },
  methods: {
    ...mapMutations({
      setContractId: 'setContractId',
      setCompany: 'setCompany',
    }),
    changeData(val: string) {
      this.company = val;
    },
    ...mapActions({
      fetchUserInfo: 'fetchUserInfo',
    }),
    validation() {
      if (!this.company) {
        this.errorMess = required('Company', this.company);
        return false;
      }
      return true;
    },
    handleSubmit() {
      const amount = this.getExigency.amount;
      const term = this.getExigency.term;
      if (this.validation()) {
        this.setCompany(this.company);
        updateCompany({
          company: this.company,
          companyId: this.companyId ? this.companyId : undefined,
        })
          .then(() => {
            const {
              utmSource,
              utmCampaign,
              utmContent,
              clickId,
            } = this.campaign;

            createLoan({
              amount,
              term,
              utmSource,
              utmCampaign,
              utmContent,
              clickId,
            }).then(res => {
              this.setContractId(res.data?.data?.id);
              setContractId(res.data?.data?.id);
              // setApproval(res.data?.data?.isSupported);
            });
          })
          .catch(err => {
            console.log(err);
            this.errorMess = err.data?.message;
          });
        this.$router.push({
          name: constRouter.EKYC_INTRO_CONFIRM.name,
        });
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.register-sucess {
  text-align: center;
}
</style>
