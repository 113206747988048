
import { defineComponent } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import BaseTextError from '@/components/Base/BaseTextError.vue';
import constRouter from '@/constants/constRouter';
import { required } from '@/helpers/validate';
import { mapMutations, mapGetters, mapActions } from 'vuex';
import { updateCompany, updateCustomer } from '@/apis/ApiOnboarding';
import { getCompany } from '@/apis/ApiGeneral';
import { createLoan } from '@/apis/ApiBorrower';
import { setContractId } from '@/helpers/localStorage';
import BaseCombobox from '@/components/Base/BaseCombobox.vue';

export default defineComponent({
  components: {
    DefaultLayout,
    BaseButton,
    BaseTextError,
    BaseCombobox,
  },
  name: 'RegisterCompany',
  data() {
    return {
      fromOTP: true,
      company: '',
      companyId: '',
      errorMess: '',
      arrCompany: [],
    };
  },
  created() {
    getCompany().then(res => {
      this.arrCompany = res.data?.data;
    });
  },
  mounted() {
    updateCustomer('', {
      monthlyIncome: this.getExigency.monthlyIncome,
      salaryDate: this.getExigency.dateSalary,
      email: this.getEmail,
    });
    this.fetchUserInfo();
  },
  computed: {
    ...mapGetters({
      getEmail: 'getEmail',
      getExigency: 'getExigency',
      campaign: 'getCampaign',
    }),
  },
  methods: {
    ...mapMutations({
      setContractId: 'setContractId',
      setCompany: 'setCompany',
    }),
    changeData(val: string) {
      this.company = val;
    },
    ...mapActions({
      fetchUserInfo: 'fetchUserInfo',
    }),
    validation() {
      if (!this.company) {
        this.errorMess = required('Company', this.company);
        return false;
      }
      return true;
    },
    handleSubmit() {
      const amount = this.getExigency.amount;
      const term = this.getExigency.term;
      if (this.validation()) {
        this.setCompany(this.company);
        updateCompany({
          company: this.company,
          companyId: this.companyId ? this.companyId : undefined,
        })
          .then(() => {
            const {
              utmSource,
              utmCampaign,
              utmContent,
              clickId,
            } = this.campaign;

            createLoan({
              amount,
              term,
              utmSource,
              utmCampaign,
              utmContent,
              clickId,
            }).then(res => {
              this.setContractId(res.data?.data?.id);
              setContractId(res.data?.data?.id);
              // setApproval(res.data?.data?.isSupported);
            });
          })
          .catch(err => {
            console.log(err);
            this.errorMess = err.data?.message;
          });
        this.$router.push({
          name: constRouter.EKYC_INTRO_CONFIRM.name,
        });
      }
    },
  },
});
