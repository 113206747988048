<template>
  <div class="base-autocomplete pl-1">
    <input
      :value="modelValue"
      type="text"
      class="focus:outline-none py-2"
      :placeholder="placeholder"
      :disabled="disabled"
      @input="autocomplete"
      @keydown="handleDown"
      autocomplete="off"
      :class="{
        'text-center': textCenter,
      }"
    />
    <div
      :id="name"
      :name="name"
      class="shadow-3 overflow-y-auto absolute text-left w-full bg-white z-30"
      style="max-height: 200px"
    ></div>
  </div>
</template>

<script>
import { removeAccents } from '@/helpers';
export default {
  emits: ['update:modelValue', 'update:modelId'],
  name: 'BaseAutoComplete',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    modelValue: {
      type: String,
      default: '',
    },
    modelId: {
      type: String,
      default: '',
    },
    textCenter: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    maxItem: {
      type: Number,
      default: 5,
    },
  },
  data: () => ({
    index: -1,
  }),
  methods: {
    handleDown(e) {
      const ele = document.getElementsByClassName('autocomplete-items');
      for (let i = 0; i < ele.length; i++) {
        ele[i].classList.remove('bg-secondary');
      }
      if (e.keyCode === 38) {
        this.index--;
        if (this.index < 0) {
          this.index = 0;
        }
        ele[this.index].classList.add('bg-secondary');
        ele[this.index].scrollIntoView({ block: 'nearest' });
      }
      if (e.keyCode === 40) {
        this.index++;
        if (this.index > ele.length - 1) {
          this.index = 0;
        }
        ele[this.index].classList.add('bg-secondary');
        ele[this.index].scrollIntoView({ block: 'nearest' });
      }
      if (e.keyCode === 13) {
        const value = ele[this.index].getElementsByTagName('input')[0].value;
        const name = ele[this.index].getElementsByTagName('input')[0].name;
        this.$emit('update:modelValue', name);
        this.$emit('update:modelId', value);
        while (ele.length > 0) {
          ele[0].remove();
        }
      }
    },

    autocomplete(event) {
      // const height = document.getElementById('myInput')?.offsetHeight;
      this.index = -1;
      function closeAllLists() {
        //xoa tat ca cac ele item
        const elmnt = document.getElementsByClassName('autocomplete-items');
        while (elmnt.length > 0) {
          elmnt[0].remove();
        }
      }

      const emit = this.$emit;
      const val = event.target.value;
      closeAllLists();
      if (!val) {
        emit('update:modelValue', val);
        emit('update:modelId', '');
        return false;
      }
      emit('update:modelValue', val);
      emit('update:modelId', '');
      const arr = this.data;
      const a = document.getElementById(`${this.name}`);
      let count = 0;
      for (let i = 0; i < arr.length; i++) {
        if (
          removeAccents(arr[i].name)
            ?.toUpperCase()
            .includes(removeAccents(val).toUpperCase())
        ) {
          count++;
          const b = document.createElement('DIV');
          b.setAttribute('class', 'autocomplete-items p-2');
          b.onmousemove = function() {
            b.style.cursor = 'pointer';
          };
          b.innerHTML += arr[i].name.substr(0, val.length);
          b.innerHTML += arr[i].name.substr(val.length);
          b.innerHTML +=
            "<input type='hidden' name='" +
            arr[i].name +
            "' value='" +
            arr[i].id +
            "'>";
          b.addEventListener('click', function() {
            const value = this.getElementsByTagName('input')[0].value;
            emit('update:modelValue', arr[i].name);
            emit('update:modelId', value);
            closeAllLists();
          });
          a.appendChild(b);
        }
        if (count >= this.maxItem) {
          break;
        }
      }

      /*Đóng auto complete khi người dùng click vào doucment*/
      document.addEventListener('click', function() {
        closeAllLists();
      });
      // set vị trí cho autocomplete
      const ele = document.getElementById(`${this.name}`);
      const rect = ele?.getBoundingClientRect();
      if (rect.bottom > window.innerHeight) {
        ele.style.bottom = `40px`;
      } else {
        ele?.style.removeProperty('bottom');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.base-autocomplete {
  position: relative;
  width: 100%;
  input {
    border-bottom: solid 1px #e5e7eb;
    width: 100%;
  }
}
</style>
